import React from 'react'
import { connect } from 'react-redux'
import { Field, FieldArray } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { isEmpty, size } from 'lodash'

// atoms
import TextInputField from '../../../atoms/TextInputField'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { containsDiacritics, isEmail } from '../../../utils/email'
import { formatFormValue } from '../../../utils/form'

class BasicEmailField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired
	}

	constructor(props) {
		super(props)
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true
	}

	validate = (email) => {
		const { povinny, t } = this.props

		if (povinny === false && isEmpty(email)) {
			return
		}

		if (isEmpty(email)) {
			return t('components:GenericFields.Pole je prázdne')
		}

		if (!isEmail(email)) {
			return t('components:GenericFields.Email nie je platný')
		}

		if (containsDiacritics(email)) {
			return t('components:GenericFields.Email nesmie obsahovať diakritiku')
		}

		if (size(email) > 2048) {
			return t('components:GenericFields.Email môže mať najviac 2048 znakov')
		}
	}

	render() {
		const { value, originalValue, cesta, editMode, columnsCount = COLUMNS_COUNT.THREE, t } = this.props

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('components:GenericFields.Kontaktný email')}</strong>
							</td>
							{(editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CREATE) && (
								<>
									{columnsCount === COLUMNS_COUNT.THREE && <td>{originalValue ?? '-'}</td>}
									<td>
										<Field
											name={cesta}
											component={TextInputField}
											placeholder={t('components:GenericFields.Zadajte kontaktný email')}
											validate={this.validate}
										/>
									</td>
								</>
							)}
							{(editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) && (
								<>
									{columnsCount === COLUMNS_COUNT.THREE && <td>{originalValue ?? '-'}</td>}
									<td>{formatFormValue(value, originalValue) || '-'}</td>
								</>
							)}
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapDispatchToProps))(BasicEmailField)
