import React from 'react'
import PropTypes from 'prop-types'
import { map, get, find, cloneDeep, isEqual, omit } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'

// actions
import * as FormAddresssesActions from '../../actions/FormAddresses'

// atoms
import Select from '../BasicSelect'
import EditAddressModal from './EditAddressModal'

// utils
import { formatAddress } from '../../utils/address'

// resources
import editPencilIcon from '../../resources/img/icons/edit-pencil.svg'
import addPlusIcon from '../../resources/img/icons/add-plus.svg'

class AddressField extends React.Component {
	static propTypes = {
		name: PropTypes.string.isRequired,
		addresses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		input: PropTypes.shape({
			value: PropTypes.any.isRequired,
			onChange: PropTypes.func.isRequired
		}).isRequired,
		disabled: PropTypes.bool,
		meta: PropTypes.shape({
			touched: PropTypes.bool.isRequired,
			error: PropTypes.string
		}).isRequired,
		formAddressesActions: PropTypes.shape({
			formAddressPush: PropTypes.func.isRequired,
			formAddressUpdate: PropTypes.func.isRequired
		}).isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		const { input } = props
		const addressId = get(input, 'value', null)

		let selectedAddress = null
		if (addressId) {
			const addresses = map(get(props, 'addresses', []), (address) => cloneDeep(address))
			selectedAddress = find(addresses, (address) => address.id == addressId)
		}

		this.state = {
			isEditModalShown: false,
			addressToEdit: null
		}
	}

	handleSelectChange = (newOption) => {
		const { input, addresses } = this.props

		const selectedAddress = addresses?.find((address) => address.id === newOption?.value?.id) ?? null

		input.onChange(selectedAddress)
	}

	handleModalCancel = () => {
		this.setState({ isEditModalShown: false })
	}

	handleModalSave = (newAddress) => {
		const { addresses, input, formAddressesActions } = this.props

		if (!newAddress) {
			return
		}

		const psc = get(newAddress, 'psc')
		const orientacneCislo = get(newAddress, 'orientacneCislo')
		const obec = get(newAddress, 'obec', null)
		const stat = get(newAddress, 'stat', null)
		if (!psc || !orientacneCislo || !obec || !stat) {
			return
		}

		if (stat == 'SK' && psc.length == 5) {
			newAddress.psc = `${psc.slice(0, 3)} ${psc.slice(3)}`
		}

		if (stat == 'CZ' && psc.length == 5) {
			newAddress.psc = `${psc.slice(0, 3)} ${psc.slice(3)}`
		}

		if (newAddress.id > 0) {
			// Úprava existujúcej adresy
			formAddressesActions.formAddressUpdate(newAddress)
			input.onChange(newAddress)
		} else {
			// Nová adresa, ktorá nemá ID (na BB posielame záporné číslo ID)

			// Skontrolujeme, či adresa už existuje, aby sme nevytvárali duplicitné adresy
			const existingAddress = find(addresses, (address) => isEqual(omit(address, ['id', 'idIsu']), omit(newAddress, ['id', 'idIsu'])))
			if (existingAddress) {
				input.onChange(existingAddress)
			} else {
				formAddressesActions.formAddressPush(newAddress)
				input.onChange(newAddress)
			}
		}

		this.setState({ isEditModalShown: false, addressToEdit: null })
	}

	getAddressOptions = () => {
		const { addresses } = this.props

		const addressesOptions = addresses?.map((address) => {
			return {
				value: address,
				label: formatAddress(address)
			}
		})

		return addressesOptions ?? []
	}

	render() {
		const { input, meta, t } = this.props
		const { isEditModalShown, addressToEdit } = this.state

		const addressOptions = this.getAddressOptions()
		const selectedAddress = addressOptions.find((addressOption) => addressOption.value.id === input.value?.id) ?? null
		const selectedAddressValue = selectedAddress?.value

		return (
			<>
				<div style={{ display: 'flex', gap: '10px', alignItems: 'flex-start' }}>
					<div style={{ flexGrow: 1 }}>
						<Select
							value={selectedAddress}
							options={addressOptions}
							classNamePrefix='react-select'
							onChange={this.handleSelectChange}
							isSearchable={false}
							placeholder={t('atoms:AddressField.Vyberte adresu')}
							// TODO: isDisabled={isDisabled}
							error={meta?.error}
						/>
					</div>
					{selectedAddressValue && (
						<img
							className='action-icon'
							style={{ marginTop: '5px' }}
							onClick={() => {
								this.setState({ isEditModalShown: true, addressToEdit: selectedAddressValue })
							}}
							src={editPencilIcon}
						/>
					)}
					<img
						className='action-icon'
						style={{ marginTop: '5px' }}
						onClick={() => {
							this.setState({ isEditModalShown: true, addressToEdit: null })
						}}
						src={addPlusIcon}
					/>
				</div>
				{isEditModalShown && <EditAddressModal onCancel={this.handleModalCancel} onSave={this.handleModalSave} addressToEdit={addressToEdit} />}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	addresses: state.formAddresses.data
})

const mapDispathToProps = (dispatch) => ({
	formAddressesActions: bindActionCreators(FormAddresssesActions, dispatch)
})

export default compose(withTranslation('atoms'), connect(mapStateToProps, mapDispathToProps))(AddressField)
